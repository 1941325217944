import dynamic from 'next/dynamic';

const CatalogPreloader = dynamic(() => import('@/components/shared/CatalogPreloader'));

const Curtain = () => {
    return (
        <div className="curtains">
            <CatalogPreloader />
        </div>
    );
};

export default Curtain;
